import styled, {keyframes} from "styled-components";
import bgImage from "./images/BannerWave.svg"
import heroImage from "./images/BGShape.svg"
import sectionbg from "./images/BGShapeContent.svg"
import buttonBg from "./images/ButtonWave.svg"
import footerBg from "./images/PatternFooter.svg"
import ctabg from "./images/ctabg.jpg"
import testimonialbg from "./images/testimonials.png"
import { Link } from "react-router-dom";

export const color ={
    primary : "#0c162f",
    white :"#FFFFFF",
    navColor :"#000",
    greyColor:"#7e828f1a",
    headingColorBlack :"#08090a",
    sectionbg: "#f5fbfc",
    paragraphColor :"#222",
    footerbgColor : "#222222",
    footerlinkcolor :"#EEEEEE",
    lineColor : "#d1cdcd",
    transparent : "transparent",
    redColor:"#ff3230"
}
export const fontSize ={
    fnSmall :"15px",
    fnNav :"18px",
    fnIconSize:"32px",
}

export const widthSize={
    wsLarge : "1000px",
    wsFull : "1200px",
}

export const screenSize={
    scXSmall : "479px",
    scSmall2 :"991px",
    scSmall3 :"1024px",
}
export const StyledBanner=styled.div`
    width:100%;
    margin:auto;
    height:auto;
    max-height:800px;
    position:relative;
    display:flex;
    img{
        width:100%;
        opacity:0.1;
        height:auto;

    }
    @media only screen and (max-width:768px){
        height:auto;
    }
`;
export const StyledBannerText= styled.div`
    position: absolute;
    font-size: 5rem;
    font-weight:bold;
    color: #FFF;
    display: flex;
    width: 60%;
    left:20%;
    justify-content: center;
    align-items: center;
    top: 50%;
    flex-direction:column;

     @media only screen and (max-width : ${screenSize.scSmall3}) and (min-width:657px){
        width:100%;
        top:40%;
        left:0;
        font-size:3rem;
    }
    @media only screen and (max-width:657px){
        width:100%;
        top:40%;
        left:0;
        font-size:1.2rem;
    }
`;
export const StyledBannerSmallText= styled.p`
    color: ${(props)=> props.color ? props.color : color.paragraphColor};
    font-size: 18px;
    line-height: 1.6;
    font-weight: 400;
    text-align:justify;
    @media only screen and (max-width:657px){
       
        font-size:10px;
    }
`;
export const StyleHeadingsmall =styled.h5`
    margin-top: ${(props)=>props.mtop ? props.mtop : "0px"};
    margin-bottom: 10px;
    color: ${color.headingColorBlack};
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.03em;

`;
export const scrollIn = keyframes`
    0%{
        height : 0px;
    }
    50% {
        height: 200px;
    }
    100%{
        height:500px;
    }

`;

export const ParagraphText = styled.p`
    color: ${(props)=> props.color ? props.color : color.paragraphColor};
    font-size: 18px;
    line-height: 1.6;
    font-weight: 400;
    text-align:justify;

    @media only screen (max-width:531px){
        font-size:12px;
    }
`;
export const ButtonWrap =styled.div`
    display: flex;
    margin-top: ${(props)=>props.mtop ? props.mtop : "0px"};
    flex-direction: column;
    align-items: ${(props)=>props.alignItem ? props.alignItem : "center"};
`;
export const Button = styled(Link)`
    padding: 20px 35px;
    border-radius: 6px;
    background-color: ${(props)=>props.bgcolor ? props.bgcolor :color.primary};
    background-image: url(${buttonBg});
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: background-color 250ms ease;
    font-size: 18px;
    text-align: center;
    display:inline-block;
    color: ${(props)=>props.bgcolor === "transparent" ? color.headingColorBlack : color.white };
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border : ${(props)=>props.bgcolor === "transparent" ? '1px solid rgba(97, 114, 131, 0.2)' : '' };
    transition : background-color 0.8s ease-in; 
`;
/***   Top Header Start Here     ***/

export const StyledHeader = styled.div`
    display:flex;
    background-color:${color.primary};
    min-height: 48px;
    padding :5px 3%;
    justify-content:center;
    align-items:center;
    background-image: url(${bgImage});
    background-position: 0% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow:hidden;
    font-size:${fontSize.fnSmall};

 @media only screen and (max-width : ${screenSize.scXSmall}){
    display:none;
 }

`;

export const StyledHeaderWarp = styled.div`
    display:flex;
    width:100%;
    max-width:${widthSize.wsLarge};
    justify-content:center;
    align-items:center;

`;

export const StyledHeaderText = styled.div`
    color :${color.white}
`;

export const StyledHeaderLink =styled(Link)`
    color :${color.white};    
    padding:${(props)=>props.padding ? props.padding : "0px"};

    :hover{
        text-decoration: underline;
        
    }
`;

/***   Top Header End Here     ***/


/***   NavBar Start Here     ***/
export const StyledNavbar = styled.div`
    display:flex;
    padding :20px 10%;
    background-color:${color.white};
    align-items:center;

    @media only screen and (max-width : ${screenSize.scSmall2}){
        position:relative;
     }
`;


export const StyledNavbarWrap = styled.div`
    width:100%;
    display:flex;
    justify-content : space-between;
    align-items:center;
    margin-left:auto;
    margin-right:auto;  

    @media only screen and (max-width : ${screenSize.scSmall2}) {
         justify-content : flex-start;
    }
`;
export const StyledNavbarWrapleft = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
`;
export const StyledNavbarWrapRight = styled.div`
    display:flex;
    align-items:center;
    flex-direction:row;
    justify-content:flex-end;
    width:70%;
`;

export const StyledNavbarLeftLogo = styled(Link)`
    margin-right:40px;
    position: relative;
    text-decoration: none;
    color: #333333;
    display:flex;
    justify-content: center;
    align-items: center;

`;
export const StyledNavLinkContainer = styled.div`
    margin-right:14px;
    position:relative;
    
    @media only screen and (max-width : ${screenSize.scSmall2}){
        display:none;
     }
    
`;
export const NavLink = styled(Link)`
    color:${(props)=>props.color ? props.color : color.navColor};
    padding: ${(props)=>props.padding ? props.padding : "5px 16px"};
    opacity:0.8;
    font-size:${fontSize.fnNav};
    position:relative;
    vertical-align:top;
    display:inline-block;
    text-align:left;
`;


export const StyledNavBurgerButton = styled.div`
     display:none;
     min-width:55px;
     border-radius:5px;
     padding:5px 8px;
     font-size:${fontSize.fnIconSize};
     text-align:center;
     position:relative;
     float:right;
     background-color : ${color.greyColor};
     @media only screen and (max-width : ${screenSize.scSmall2}){
        display:block;
     }
`;

export const StyledNavOpenPopup = styled.div`
    position:absolute;
    top:80%;
    right:0%;
    width:30%;
    height:100vh;
    background-color:${color.primary};
    transition :ease-in-out 10s ${scrollIn};
    z-index:500;
    box-shadow: 0 50px 80px -30px rgba(53, 52, 72, 0.2);
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-end;
    opacity:0;
:a{
    color:${color.white} !important;
  }
    @media only  screen and (max-width:991px){
        opacity:1
    } 
   @media only screen and (max-width :768px) and (min-width : 425px){
        width:50%;
   }
    @media only screen and  (max-width : 425px){
        width:80%;
   }
`;

/***   NavBar End Here     ***/

/***    Hero Section Start Here ***/

export const StyledHeroSection = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0px 0%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 100% 0%;
    background-size: auto;
    background-repeat: no-repeat;
    min-height:200px;
    border-top: 2px solid ${color.redColor};
    background-color:${color.primary};
`;

export const StyledHeaderSectionContainer = styled.div`
    
     width:100%;
     padding:0px 0%;
     flex-direction:row;
     justify-content:center;
     align-items:center;
     @media only screen and (max-width: ${screenSize.scSmall2}){
        flex-wrap:wrap;
     }
`;

export const StyledHeaderHerocontent =styled.div`
     float:left;
     width:45%;
     padding-right:40px;

     @media only screen and (max-width: ${screenSize.scSmall2}){
        width: 100%;
        max-width: 730px;
        margin-bottom: 40px;
        padding-left: 40px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
     }
`;

export const StyledHeaderHeroImage =styled.div`
     float:right;
     width:45%;
     flex-direction:column;
     justify-content:center;
     align-items:flex-end;
     @media only screen and (max-width: ${screenSize.scSmall2}){
        width: 100%;
        margin-top: 60px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
     }
`;
export const StyledHeaderHeroHeading = styled.span`
    display:flex;
    justify-content: center;
    align-items:center;
    font-weight:bold;
    margin-bottom: 20px;
    color: ${(props)=>props.color ? props.color : color.white};
    font-size: 30px;
    flex-direction:column;
    line-height: 1;
    letter-spacing: -0.03em;

    @media only screen and (max-width:520px){
        font-size:20px;
        justify-content: center
        align-items:center;
    }
`;

/***    Hero Section Ends Here ***/
/***    Content Section Ends Here ***/
export const StyledSectionwithoutbg = styled.div`
        position: relative;
        padding: ${(props)=>props.padding ? props.padding : "60px 10%"};
        background-color: transparent;
        min-height:${(props)=>props.mnheight ? props.mnheight : "0px"};
`;
export const StyledSection = styled.div`
        position: relative;
        padding: 60px 10%;
        background-color: transparent;
        background-image: url(${sectionbg});
        background-position: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        @media only screen and (max-width:991px){
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: ${color.sectionbg};
        background-image: none;
        background-size: auto;
        background-repeat: repeat;
        }
`;
export const StyledSectionTitle = styled.div`
        position:relative;
        display:flex;
        width:100%;
        max-width:670px;
        margin:40px auto;
        text-align:center;
        flex-direction:column;
`;
export const StyledSectionContainer = styled.div`
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: ${(props)=>props.padding};
`;

export const StyledSectionTwogridLayout = styled.div`
     display:grid;
     grid-auto-columns : 1fr;
     grid-template-columns : ${(props)=>props.tcolumns ? props.tcolumns : "1fr 1fr"};
     grid-template-rows :${(props)=>props.trows ? props.trows : "auto"};
     grid-row-gap: ${(props)=>props.trowgap};
     grid-column-gap:${(props)=>props.tcolumngap};

     @media only screen and (max-width:1024px){
        grid-template-columns:1fr;
     } 
`;
export const StyledCard = styled.div`
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
     text-align:center;
     background : ${(props)=> props.bgcolor ? props.bgcolor : color.transparent};
`;
export const StyledMainCardRow = styled.div`
    display: flex;
    justify-content : space-between;
     flex-wrap: wrap;
     @media only screen and (max-width:1024px) and (min-width:767px){
        display:flex;
     }
     @media only screen and (max-width:768px){
        display:flex;
        flex-direction: row;
     }
`;
export const StyledMainCard = styled.div`
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
     background : ${(props)=> props.bgcolor ? props.bgcolor : color.transparent};
     width:${(props)=>props.width ? props.width : "0px"};
     border-radius:${(props)=>props.radius ? props.radius : "0px"};
     box-shadow:0px 10px 60px 0px rgba(0, 0, 0, 0.1);
     position:relative;
     margin :${(props)=>props.margin ? props.margin : "0px"};
     overflow:hidden;
     z-index:9;
     min-height:200px;
     @media only screen and (max-width:1024px) and (min-width:767px){
        width :45%;
     }
     @media only screen and (max-width:768px){
        width :100%;
     }
`;
export const StyledCardBottom = styled.div`
    background-color:${color.primary};
    width:100%;
    min-height:50px;
    background-image: url(${buttonBg});
    background-position: 0% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    display:flex;
    justify-content:flex-end;
    align-items:center;
`;
export const StyledMainCardContent = styled.div`
    padding:40px 50px;
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
`;
export const StyledMainCardCricle=styled.div`
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    background-color: ${color.primary};
    border: 9px solid ${color.white};
    margin: 0 0 -100px 0;
    z-index: 100;
    position: absolute;
    bottom:40%;
    left:10px;
    transition: all 0.4s ease-in-out;
`;
export const StyledMaincardIconbg= styled.div`
    position:absolute;
    top:0px;
    right:0px;
`;
export const StyledCardWrap = styled.div`
     @media only screen and (max-width:991px){
        padding :0px 40px;
     }
`;
export const StyledLinecard = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding-bottom:140px;

    @media only screen and (max-width:991px){
        flex-direction:column;
        padding-bottom:0px;
    }
`;
export const StyledLine = styled.div`
    width: 100%;
    height: 1px;
    border-style: dashed none none;
    border-width: 1.3px;
    border-color: ${color.lineColor};
    @media only screen and (max-width:991px){
        width: 1px;
        height: 100%;
        min-height: 100px;
        border-style: none dashed none none;
    }
`;
export const StyledContenttext = styled.div`
     display:flex;
     flex-direction:column;
     justify-content : center;
     align-items: flex-start;

     @media only screen and (max-width:991px){
        text-align:center;
        align-items:center;
     }
`;

export const StyledContentImage =styled.div`
    display:flex;
    justify-content : center;
    align-items: center;
`;
export const StyledContentHeading = styled.h2`
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${color.primary};
    font-size: 55px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.03em;

    @media only screen and (max-width:768px) and (min-width:531px){
        font-size:40px;
    }
    @media only screen and (max-width:531px){
        font-size:30px;
    }
`;
/***    Content Section Ends Here ***/

/***    Footer Section Start Here ***/

export const StyledFooter = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 100px 3% 60px;
    flex-direction: column;
    align-items: center;
    background-color: ${color.footerbgColor};
    background-position: 0% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    text-decoration: none;
}
`;

export const StyledFooterContainer =styled.div`
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
`;

export const StyledFooterContainerLayout = styled.div`
    display:grid;
    grid-auto-columns : 1fr;
    grid-row-gap: 16px;
    grid-column-gap:16px;
    grid-template-columns : 2fr 1fr 1fr 1fr 1fr;
    grid-template-row: auto;

    @media only screen and (max-width:991px){
        grid-column-gap: 24px;
        grid-row-gap: 50px;
    }
    @media only screen and (max-width:767px){
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width:479px){
        grid-template-rows: auto auto auto auto;
    }
`;

export const StyledFooterColumn =styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const StyledFooterHeadinglogo = styled.h1`
    color: ${color.white};
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.03em;
    margin:0px;
    padding:0px;
`
export const StyledFooterHeadingLink = styled.h3`
    color: ${color.white};
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.03em;
    margin:0px 0px 20px 0px;
    padding:0px;
`
export const StyledFooterLink = styled(Link)`
    margin-bottom: 12px;
    padding-top: 10px;
    padding-right: 6px;
    padding-bottom: 8px;
    color: ${color.footerlinkcolor};
    font-size: 16px;
    text-decoration: none;
`;
/***    Footer Section Ends Here ***/

/***    Portofilo Section Start Here ***/
export const StyledPortfoliotabs = styled.div`
    display:flex;
    width :100%;
    justify-content:center;
    align-items:center;
    position:relative;
    margin-bottom:40px;

    a{
        margin: 10px 20px;
    }
    @media only screen and (max-width:571px){
        display:grid;
    }
`;
export const StyledPortfolioBox = styled.div`
    display:flex;
    width :100%;
    justify-content:center;
    align-items:center;
`;

export const StyledPortfoliolist = styled.div`
   display:flex;
   flex-direction: row;
    @media only screen and (max-width:991px){
        flex-direction: column;
    
    }
`;
export const StyledPortofiloListitem = styled.div`
    width:50%;
    margin-bottom: 20px;
    margin-left:0px;
    margin-right:0px;
    position:relative;
    display:flex;
    min-height:1px;
    
    @media only screen and (max-width:991px){
        width:100%;
    
    }
`;

export const StyledPortfolioCard = styled.div`
    display:flex;
    min-height: 200px;
    width:100%;
    padding:25px;
    align-items:center;
    margin:0px 10px;
    border : solid 1px;
    border-color: rgba(53, 184, 190, 0.15);
    border-radius: 6px;
    background-color : ${color.white};
    transition : border-color  300ms ease;

    &:hover{
        border-color: rgba(53, 184, 190, 0.5);
    }
    @media only screen and (max-width:571px){
        display:block;
    }
`;

export const StyledPortfolioCardImage = styled(Link)`
    overflow: hidden;
    max-height: 120px;
    max-width: 120px;
    min-height: 120px;
    min-width: 120px;
    margin-right: 30px;
    border-radius: 8px;
    display:inline-block;
    @media only screen and (max-width:571px){
         max-height: 200px;
        max-width: 100%;
        min-height: 200px;
        min-width: 100%;
    }

`;
export const StyledPortfolioCardContent = styled.div`
    width:100%;
`;
export const StyledPortfolioCardHeading = styled(Link)`
    display: flex;
    margin-bottom: 4px;
    justify-content: left;
    align-items: flex-start;
    text-decoration: none;
    flex-direction: column;
`;
/***    Portofilo Section Ends Here ***/
/***    Inner Pages Start Here   ***/
export const HeaderSection =styled.div`
    display:flex;
    justify-content : center;
    padding: 100px 3%;
    min-height : 200px;
    align-items:center;
    background-color: ${color.footerbgColor};
`;
export const HederSectionContainer= styled.div`
    display:flex;
    justify-content:center;
    alogn-item:center;
    width:100%;
    max-width: 1200px;
    flex-direction:row;

`;

export const StyledCta = styled.div`
    display: flex;
    min-height: 300px;
    padding: 100px 3%;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.67), rgba(25, 26, 27, 0.19)), url(${ctabg});
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    background-attachment: scroll, fixed;
`;

export const StaticContent = styled.div`
    color: ${color.white};
    text-align:center;

    h1{
        margin-bottom: 0px;
        color: ${color.white};
        font-size: 5em;
        margin-top: 20px;
        line-height: 1;
        font-weight: 700;
        letter-spacing: -0.03em;
    }
    img{
        margin: 20px 0px;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
    }
    p{
        font-size:20px;
    }
`;

export const StyledTeamCard = styled.div`
    width : 100%;
    display : flex;
    align-items: center;
    justify-content : center;
    position: relative;

    
`;

export const StyledTeamCardContainer = styled.div`
    padding : ${(props)=>props.padding ? props.padding : "5px"};
    border : 1px solid ${color.primary};
    background-color : ${color.white};
    min-height: ${(props)=>props.mnheight ? props.mnheight : 430}px;
    border-radius: 4px;
    position: relative;

    img{
        width :100%;
        border-radius:4px;
    }
    div.overlay{
        display:flex;
        position: absolute;
        width : 100%;
        height :0%;
        top:0;
        left:0;
        background: ${(props)=>props.backgroundcolor ? props.backgroundcolor : 'rgb(0,0,0,0.7)'} center;
        box-shadow: 15px 15px 10px #CCC;
        opacity: 0;
        transition: height ease-in 1s;
        color:${color.primary};
        font-size:24px;
        justify-content:center;
        align-items:center;
    }
    div.overlay a{
        color:${color.primary};
        border-radius:50%;
        background-color: ${color.transparent};
        text-decoration:none;
        padding:10px 12px;
        outline:none;
        margin:0px 10px;
        transition: background-color ease-in-out 0.5s;
    }

    &:hover div.overlay{
        opacity:1;
        height:100%;
    }
    div.overlay a:hover{
        color:${color.white};
        background-color:${color.primary};

    }
`;

export const StyledTeamCardContent = styled.div`
        margin: 10px 0px;
`;

export const StyledCardHeading = styled.h5`
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-transform:uppercase;
    padding :0px 5px;   
    margin:0px;
    display: flex;
    justify-content : space-between;

    a{
        font-size: 20px;
        background-color: ${color.headingColorBlack};
        padding: 4px 5px;
        color: ${color.white};
        border-radius : 3px;
        transition : background-color 0.8s ease-in; 
    }
    a:hover{
        background-color: ${color.primary};
        
    }
`;


export const StyledCardSubHeading = styled.h6`
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.03em;
    padding :10px 5px;   
    margin:0px;
    display: flex;
    justify-content : flex-start;
    color: ${color.primary};
`;

export const StyledTestimonialsContainer = styled.div`
    grid-column-gap: 22px;
    grid-row-gap: 22px;
    grid-template-rows: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
`;
export const StyledtestimonialImage = styled.div`
    background-image: url(${testimonialbg});;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const StyledtestimonialContent = styled.div`
    display: flex;
    padding: 20% 15%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: ${color.primary};
    background-position: 0px 0px;
    background-size: contain;
    background-repeat: no-repeat;
`;


export const StyledServicecontainer= styled.div`
    display:flex;
    flex-direction:center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyleServiceMainCard= styled.div`
        display:flex;
        flex-direction:center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
`;
export const StyledServiceCard= styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width : 20%;
    margin: 20px 15px;
    border: 1px solid rgba(97, 114, 131, 0.2);
    border-radius: 5px;
    min-height:200px;
    background:${color.transparent};
    padding: 0px 40px;
    transition: background 1s ease-in-out;
    &:hover{
        background:${color.footerbgColor};
    }
    &:hover h5{
        color:${color.white}
    }

    @media screen and (max-width:1024px) and (min-width:768px){
        width:40%;
    }
`;

export const StyledLoadercard =styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    margin:auto;
    padding: 3%;
    justify-content:center;
    align-items:center;
    height:70vh;
`;
/***    Inner Pages Ends Here   ***/

