 import {color,StyledMainCard,StyledMaincardIconbg,StyledMainCardContent,StyledMainCardCricle,StyledCardBottom,StyledHeaderLink} from "../style"
 
 const MainCard =({id,heading, description, bgIcon, footerIcon, iscardbottom})=>{
    console.log("sds"+description.substring(0,100))
    return (
        <StyledMainCard bgcolor={color.white} margin="1rem 0rem" width="30%" radius="10px" key={id}>
         {bgIcon && 
            <StyledMaincardIconbg>
                <img src={bgIcon} alt="iconbg1" />
            </StyledMaincardIconbg>
         }   
        
        <StyledMainCardContent>
            <h2>{heading}</h2>
            <p>{description.length > 80 ? description.substring(0,80)+"..." : description}</p>
        </StyledMainCardContent>
        {footerIcon && 
            <StyledMainCardCricle>
                <img src={footerIcon} alt="icom" />
            </StyledMainCardCricle>
        }
        {iscardbottom &&
             <StyledCardBottom>
                <StyledHeaderLink to="/" padding="0px 20px">Read More ...</StyledHeaderLink>
            </StyledCardBottom>
        }
       
    </StyledMainCard>
    )
}

export default MainCard