import {
    HeaderSection, HederSectionContainer, StyledSectionTitle, StyledHeaderHeroHeading, ParagraphText, color,
    StyledSectionwithoutbg, StyledSectionContainer, StyledSectionTwogridLayout, StyledContenttext, StyledContentHeading,
    StyledContentImage,
    StyledSection,
    StyledCta,
    StaticContent
} from "../style"
const Faq=()=>{
    return(
        <>
        <HeaderSection>
            <HederSectionContainer>
                <StyledSectionTitle>
                    <StyledHeaderHeroHeading>Frequently Asked <span style={{ color: color.primary }}> Questions</span></StyledHeaderHeroHeading>
                    <ParagraphText color={color.white}>Use our menu to place an order online, or phone our store to place a pickup order. Fast and fresh food.</ParagraphText>
                </StyledSectionTitle>
            </HederSectionContainer>
        </HeaderSection>
        <StyledSectionwithoutbg>

        </StyledSectionwithoutbg>
    </>
    )
}


export default Faq