export const headerNavigation =[
    {
        id:1,
        name:"Home",
        pageHeading: "Home",
        pageSubHeading :"",
        bannerImage :"",
        link:"/"
    },
    {
        id:2,
        name:"Services",
        pageHeading: "Our Services",
        pageSubHeading :"We offers the following services, we have experties in all these given services",
        bannerImage :"",
        link:"/services"
    },
    {
        id:3,
        name:"About",
        pageHeading: "Our Journey",
        pageSubHeading :"Know about us in details, when we start our Corporate Journey",
        bannerImage :"",
        link:"/about"
    },
    {
        id:4,
        name:"Team",
        pageHeading: "Our Team",
        pageSubHeading :"",
        bannerImage :"",
        link:"/team"
    },
    {
        id:5,
        name:"Portfolio",
        pageHeading: "Our Portfolio",
        pageSubHeading :"",
        bannerImage :"",
        link:"/portfolio"
    },
    {
        id:6,
        name:"Contact",
        pageHeading: "Contact Us",
        pageSubHeading :"",
        bannerImage :"",
        link:"/contact",
        hasSubMenu :true,
        submenu :[{
            id:1,
            name:"Contact",
            pageHeading: "Contact Us",
            pageSubHeading :"",
        },{
            id:2,
            name:"Contact",
            pageHeading: "Contact Us",
            pageSubHeading :"",
        }]
    },
    

]