import {
    HeaderSection, HederSectionContainer, StyledSectionTitle, StyledHeaderHeroHeading, ParagraphText, color,
    StyledSectionwithoutbg, StyledSectionContainer, StyledSectionTwogridLayout, StyledContenttext, StyledContentHeading,
    StyledContentImage,
    StyledSection,
    StyledCta,
    StaticContent
} from "../style"
import waveImg from "../images/wave.svg"
import aboutImg from "../images/about.png"
import whyus from "../images/whyus.png"

const About = () => {
    return (
        <>
            <HeaderSection>
                <HederSectionContainer>
                    <StyledSectionTitle>
                        <StyledHeaderHeroHeading>About Our <span style={{ color: color.primary }}> Company</span></StyledHeaderHeroHeading>
                        <ParagraphText color={color.white}>Use our menu to place an order online, or phone our store to place a pickup order. Fast and fresh food.</ParagraphText>
                    </StyledSectionTitle>
                </HederSectionContainer>
            </HeaderSection>


            <StyledSectionwithoutbg>
                <StyledSectionContainer>
                    <StyledSectionTwogridLayout trowgap="16px" tcolumngap="44px">
                        <StyledContenttext>
                            <StyledContentHeading>
                            Young  .<span style={{color:color.footerbgColor}}>Agile </span>Lean Competitive
                            </StyledContentHeading>
                            <ParagraphText>
                            D Techno Minds, a premier brand under Ditya International Pvt Ltd, empowers businesses with cutting-edge, customized IT solutions. Our team of seasoned technologists leverages expertise in Artificial Intelligence (AI), Machine Learning (ML), Enterprise Resource Planning (ERP), website and mobile application development, and now, cloud infrastructure management.
                            </ParagraphText>
                            <ParagraphText>
                            D-Techno Minds is dedicated to excellence, continually improving to deliver top-notch services. Rigorous quality assurance ensures every project meets strict standards, providing clients with reliable and high-performing solutions.
                            </ParagraphText>
                            <ParagraphText>
                            We prioritizes client satisfaction, tailoring solutions to meet each business’s unique needs and challenges. This personalized approach ensures effective and efficient results for every client.
                            </ParagraphText>
                            
                        </StyledContenttext>
                        <StyledContentImage>
                            <img src={aboutImg} alt="ContentImg" />
                        </StyledContentImage>
                    </StyledSectionTwogridLayout>
                </StyledSectionContainer>
            </StyledSectionwithoutbg>


            <StyledSection>
                <StyledSectionContainer>
                    <StyledSectionTwogridLayout trowgap="16px" tcolumngap="44px">
                        <StyledContentImage>
                            <img src={whyus} alt="WhyUS" />
                        </StyledContentImage>
                        <StyledContenttext>
                            <StyledContentHeading>
                                The home of fresh products
                            </StyledContentHeading>
                            <ParagraphText>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.
                            </ParagraphText>
                        </StyledContenttext>
                    </StyledSectionTwogridLayout>
                </StyledSectionContainer>
            </StyledSection>

            <StyledCta>
                <StyledSectionContainer>
                <StyledSectionTwogridLayout tcolumns="1fr  1fr 1fr 1fr" trowgap="16px" tcolumngap="16px">
                    <StaticContent>
                        <h1>2000</h1>
                        <img src={waveImg} alt="waveImg" />
                        <p>Happy Customer</p>
                    </StaticContent>
                    <StaticContent>
                        <h1>15</h1>
                        <img src={waveImg} alt="waveImg" />
                        <p>ERP Solutions</p>
                    </StaticContent>
                    <StaticContent>
                        <h1>300</h1>
                        <img src={waveImg} alt="waveImg" />
                        <p>Team Size</p>
                    </StaticContent>
                    <StaticContent>
                        <h1>32</h1>
                        <img src={waveImg} alt="waveImg" />
                        <p>Live Projects</p>
                    </StaticContent>
                </StyledSectionTwogridLayout>
                </StyledSectionContainer>
            </StyledCta>
        </>

    )
}


export default About