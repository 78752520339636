import { StyledFooter, StyledFooterColumn, StyledFooterContainer, StyledFooterContainerLayout, StyledFooterHeadingLink, StyledFooterHeadinglogo, StyledFooterLink, color } from "../style"

export const Footer=()=>{
    return(
        <StyledFooter>
            <StyledFooterContainer>
                <StyledFooterContainerLayout>
                    <StyledFooterColumn>
                        <StyledFooterHeadinglogo>DTech <span style={{color : color.primary}}>Minds</span></StyledFooterHeadinglogo>
                    </StyledFooterColumn>
                    <StyledFooterColumn>
                        <StyledFooterHeadingLink>Company</StyledFooterHeadingLink>
                            <StyledFooterLink to="/">Home</StyledFooterLink>
                            <StyledFooterLink to="/about">About</StyledFooterLink>
                            <StyledFooterLink to="/history">History</StyledFooterLink>
                            <StyledFooterLink to="/team">Team</StyledFooterLink>
                            <StyledFooterLink to="/portfolio">Protfolio</StyledFooterLink>
                    </StyledFooterColumn>
                    <StyledFooterColumn>
                    <StyledFooterHeadingLink>Services</StyledFooterHeadingLink>
                        <StyledFooterLink>ERP Solutions</StyledFooterLink>
                        <StyledFooterLink>EGov Solutions</StyledFooterLink>
                        <StyledFooterLink>Software Development</StyledFooterLink>
                        <StyledFooterLink>User Interface Design</StyledFooterLink>
                        <StyledFooterLink>Technical Conslutant</StyledFooterLink>
                    </StyledFooterColumn>
                    <StyledFooterColumn>
                    <StyledFooterHeadingLink>Other</StyledFooterHeadingLink>
                        <StyledFooterLink to="/faq">FAQ</StyledFooterLink>
                        <StyledFooterLink to="privacy">Privacy Policy</StyledFooterLink>
                        <StyledFooterLink to="datascurity">Data Security</StyledFooterLink>
                        
                    </StyledFooterColumn>
                </StyledFooterContainerLayout>
            </StyledFooterContainer>
        </StyledFooter>
    )
}



export default Footer