import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { portfoliodata } from "../data/portfoliodata"
import fallbackSrc from "../images/portfolio/noimage.png"
import { Button, ButtonWrap, ParagraphText, StyleHeadingsmall, StyledPortfolioCard, StyledPortfolioCardContent, StyledPortfolioCardHeading, StyledPortfolioCardImage, StyledPortfoliolist, StyledPortofiloListitem } from "../style"
const ProtfolioBox = ({category})=>{
    
    return (
       
        <StyledPortfoliolist>
            
            
                {portfoliodata.filter((items)=>items.category === category).map((items)=> (
                    <StyledPortofiloListitem key={items.id}>
                          <StyledPortfolioCard>
                              <StyledPortfolioCardImage id={items.name} style={{}} to="/">
                                    
                                    <img src={items.image} alt="TestImage" />
                                </StyledPortfolioCardImage>
                                <StyledPortfolioCardContent>
                                    <StyledPortfolioCardHeading id={items.name} style={{}} to="/">
                                        <StyleHeadingsmall>
                                            {items.name}
                                        </StyleHeadingsmall>
                                        <ParagraphText>
                                            {items.description.substring(0,100)}....
                                        </ParagraphText>
                                    </StyledPortfolioCardHeading>
                                    <ButtonWrap alignItem="flex-end">
                                            <Button><FaChevronRight /></Button>
                                    </ButtonWrap>
                                </StyledPortfolioCardContent> 
                        </StyledPortfolioCard> 
                    </StyledPortofiloListitem>
                ))}
                
                          
               
           
        </StyledPortfoliolist>
    )
}



export default ProtfolioBox