import HeroSection from "../components/herosection"
import ProtfolioBox from "../components/portfolioBox"
import {
    Button, ButtonWrap, ParagraphText, StyleHeadingsmall, StyledCard, StyledCardBottom, StyledCardWrap, StyledContentHeading, StyledContentImage, StyledContenttext,
    StyledHeaderLink,
    StyledLine, StyledLinecard, StyledMainCard, StyledMainCardContent, StyledMainCardCricle, StyledMainCardRow, StyledMaincardIconbg, StyledPortfoliotabs, StyledSection,
    StyledSectionContainer, StyledSectionTitle, StyledSectionTwogridLayout, StyledSectionwithoutbg,
    StyledServicecontainer,StyleServiceMainCard,
    color
} from "../style"
import contentImg from "../images/aboutnew.png"
import iconbg1 from "../images/box-icon-bg1.webp"
import fticon from "../images/icon-1.webp"
import { useState } from "react"
import {mainCardData} from "../data/homedata"
import MainCard from "../components/card"
import Services from "../components/services"

export const Home = () => {
   const [toggle, setToggle]= useState(1)
   const [category, setCategory] = useState("ERP")
    const handleToggle = (index, categoryItem)=>{
        setToggle(index);
        setCategory(categoryItem)
    }
    
    return (
        
        <div>
           { <HeroSection /> }
            <StyledSectionwithoutbg>
                <StyledSectionContainer padding="5px 25px"> 
                    <StyledMainCardRow>
                        {mainCardData.map((items)=>{
                            return(
                                <MainCard
                                id={items.id}
                                heading={items.heading}
                                description={items.description}
                                iscardbottom={items.iscardbottom}
                                bgIcon={items.iconbg}
                                footerIcon={items.footerIcon}
                            />
                            )
                        
                        })}    
                    </StyledMainCardRow>                    
                </StyledSectionContainer>
            </StyledSectionwithoutbg>
            { <StyledSection>
                <StyledSectionContainer>
                    <StyledSectionTwogridLayout trowgap="16px" tcolumngap="44px">
                        <StyledContenttext>
                            <StyledContentHeading>
                            Empower Your Clients With  <span style={{color:color.headingColorBlack}}>Industry-Leading </span> Technology Solution
                            </StyledContentHeading>
                            <ParagraphText>
                            D Techno Minds, a premier brand under Ditya International Pvt Ltd, empowers businesses with cutting-edge, customized IT solutions. Our team of seasoned technologists leverages expertise in Artificial Intelligence (AI), Machine Learning (ML), Enterprise Resource Planning (ERP), website and mobile application development, and now, cloud infrastructure management..</ParagraphText>
                           <ParagraphText> D-Techno Minds is dedicated to excellence, continually improving to deliver top-notch services. Rigorous quality assurance ensures every project meets strict standards, providing clients with reliable and high-performing solutions.
                            </ParagraphText>
                            <ButtonWrap>
                                <Button>Know More..</Button>
                            </ButtonWrap>
                        </StyledContenttext>
                        <StyledContentImage>
                            <img src={contentImg} alt="ContentImg" />
                        </StyledContentImage>
                    </StyledSectionTwogridLayout>
                </StyledSectionContainer>
            </StyledSection>}
            <StyledSectionwithoutbg>
                <StyledSectionContainer padding="5px 25px">
                    <StyleServiceMainCard>
                            <Services />
                    </StyleServiceMainCard>
                </StyledSectionContainer>
            </StyledSectionwithoutbg>     
        <StyledSection>
        <StyledSectionContainer>

         
                    <StyledSectionTitle>
                        <StyledContentHeading>Portofilo</StyledContentHeading>
                        <ParagraphText>Use our menu to place an order online, or phone our store to place a pickup order. Fast and fresh food.</ParagraphText>
                    </StyledSectionTitle>
                    <StyledPortfoliotabs>
                        <Button bgcolor= {toggle== 1 ? color.primary : color.transparent}
                            onClick={()=>handleToggle(1, "ERP")}
                        >ERP</Button>
                        <Button bgcolor= {toggle== 2 ? color.primary : color.transparent}
                            onClick={()=>handleToggle(2, "EGov")}
                        >EGov</Button>
                        <Button bgcolor= {toggle== 3 ? color.primary : color.transparent}
                            onClick={()=>handleToggle(3, "Cloud Solution")}
                        >Cloud Solution</Button>
                    </StyledPortfoliotabs>

                  {  <ProtfolioBox category={category} ></ProtfolioBox> }
             
            </StyledSectionContainer>
        </StyledSection> 
        </div>
    )
}



export default Home