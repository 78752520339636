import {
    HeaderSection, HederSectionContainer, StyledSectionTitle, StyledHeaderHeroHeading, ParagraphText, color,
    StyledSectionwithoutbg, StyledSectionContainer, StyledSectionTwogridLayout,
    StyledCard, ButtonWrap, Button,
    StyledTestimonialsContainer,
    StyledtestimonialImage,
    StyledtestimonialContent
   
} from "../style"
import {teamdata} from "../data/teamdata"
import Teamplayer from "../components/teamplayer"
import testimonialsbg from "../images/testimonials.webp"

const Team = () => {
    console.log(teamdata.length)
    return (
        <>
            <HeaderSection>
                <HederSectionContainer>
                    <StyledSectionTitle>
                        <StyledHeaderHeroHeading>Our<span style={{ color: color.primary }}> Team</span></StyledHeaderHeroHeading>
                        <ParagraphText color={color.white}>Use our menu to place an order online, or phone our store to place a pickup order. Fast and fresh food.</ParagraphText>
                    </StyledSectionTitle>
                </HederSectionContainer>
            </HeaderSection>


            <StyledSectionwithoutbg>
                <StyledSectionContainer>
                    <StyledSectionTwogridLayout tcolumns="1fr  1fr 1fr" trowgap="36px" tcolumngap="50px">
                        {teamdata.map((items) =>(
                            <Teamplayer  
                                name={items.name} 
                                id={items.id} 
                                designation={items.designation}
                                image={items.image}
                                linkedlink={items.linkedinlink}
                                description={items.description}
                            />
                        ))}
                        
                    </StyledSectionTwogridLayout>
                </StyledSectionContainer>
            </StyledSectionwithoutbg>

            <StyledSectionwithoutbg padding="1%">
                <StyledTestimonialsContainer>
                    <StyledtestimonialImage></StyledtestimonialImage>
                    <StyledtestimonialContent>
                        <StyledHeaderHeroHeading><span style={{ color: color.headingColorBlack }}> Support</span> good food and local business.</StyledHeaderHeroHeading>
                        
                    </StyledtestimonialContent>
                </StyledTestimonialsContainer>
            </StyledSectionwithoutbg>
           

            
        </>

    )
}


export default Team