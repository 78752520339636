export const teamdata = [
    {
        id:1,
        name : "Palam Garg",
        image : "ceo.jpg",
        description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        designation : "Founder and CEO",
        linkedinlink : "https://www.linkedin.com/home/?originalSubdomain=in"
    },
    {
        id:2,
        name : "Simranjeet Singh",
        image : "ceo.jpg",
        description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        designation : "Technical Head",
        linkedinlink : "https://www.linkedin.com/home/?originalSubdomain=in"
    },
    {
        id:3,
        name : "Ashutosh Singh",
        image : "ceo.jpg",
        description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        designation : "UI/ UX Head",
        linkedinlink : "https://www.linkedin.com/home/?originalSubdomain=in"
    },
    {
        id:4,
        name : "Ranjana Garg",
        image : "ceo.jpg",
        description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        designation : "Account and Legal Head",
        linkedinlink : "https://www.linkedin.com/home/?originalSubdomain=in"
    },
    {
        id:5,
        name : "Ranjana Garg",
        image : "ceo.jpg",
        description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        designation : "Account and Legal Head",
        linkedinlink : "https://www.linkedin.com/home/?originalSubdomain=in"
    }
]