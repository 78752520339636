import { ParagraphText, StyledCardHeading, StyledCardSubHeading, StyledTeamCard, StyledTeamCardContainer, StyledTeamCardContent } from "../style"
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { Link } from "react-router-dom"

const Teamplayer = ({id, name, designation, image, linkedlink, description})=>{
    console.log(image)
    return(
        <StyledTeamCard key={id}>
            <StyledTeamCardContainer>
                <img src= {require(`../images/teams/${image}`)}  alt={name} />    
                <StyledTeamCardContent>
                        <StyledCardHeading>{name}
                            
                           
                        </StyledCardHeading>
                        <StyledCardSubHeading>{designation}</StyledCardSubHeading>
                    <ParagraphText>
                        {description}
                    </ParagraphText>
                </StyledTeamCardContent>
                    
               
                <div className="overlay">
                    <Link to= {linkedlink}><FaLinkedinIn /></Link>
                    <Link to= {linkedlink}><FaFacebookF /></Link>
                    <Link to= {linkedlink}><FaTwitter /></Link>
                </div>    
            </StyledTeamCardContainer>
           
        </StyledTeamCard>
    )
}



export default Teamplayer