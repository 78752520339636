import { Button, ButtonWrap, ParagraphText, StyledBanner, StyledBannerText,StyledBannerSmallText, StyledHeaderHeroHeading, StyledHeaderHeroImage, StyledHeaderHerocontent, StyledHeaderSectionContainer, StyledHeroSection, color } from "../style"
import heroImage from "../images/slider/HeroImage.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderData } from "../data/homedata"
import bannerimg from "../images/slider/banner1.jpg"
import bannerimg1 from "../images/slider/banner2.jpg"
const HeroSection = () => {
    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 2000,

    };
    return (
      //  https://raw.githubusercontent.com/anujkit/dtmimage/main/banner5.jpg
      <StyledHeroSection>
            <StyledHeaderSectionContainer>
            <StyledBanner>
                <StyledBannerText>Mordern Workplace Services
                    <StyledBannerSmallText color={color.white}>Drive efficiency & agility with our digital transformation services</StyledBannerSmallText>
                </StyledBannerText>
                <img src="https://raw.githubusercontent.com/anujkit/dtmimage/main/banner5.jpg" alt="banner Img" />
                
            </StyledBanner> 
            </StyledHeaderSectionContainer>
      </StyledHeroSection>
    )
}

export default HeroSection