
import { NavLink, StyledHeader,StyledHeaderHeroHeading,color, StyledHeaderLink, StyledHeaderText, StyledHeaderWarp, StyledNavBurgerButton, StyledNavLinkContainer, StyledNavOpenPopup, StyledNavbar, 
    StyledNavbarLeftLogo,
    StyledNavbarWrap, StyledNavbarWrapRight, StyledNavbarWrapleft } from "../style"
import logoImage from "../images/logo.jpg"
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import {headerNavigation} from "../data/navigationdata"
 const Header = ()=>{
    const [btnColor, setBtnColor] = useState(false);
    const [openNav, setOpenNav] = useState(true)
    const handleOpenNav =()=>{
        setBtnColor(!btnColor)
        setOpenNav(!openNav)
    }
       return <>
            <StyledHeader>
                <StyledHeaderWarp>
                    <StyledHeaderText>
                        Ready to know more? Reach out to us for additional information!. <StyledHeaderLink to="/">Order Now</StyledHeaderLink>
                    </StyledHeaderText>
                </StyledHeaderWarp>
            </StyledHeader>
                
                    
            <StyledNavbar>
                <StyledNavbarWrap>
                    <StyledNavbarWrapleft>
                    <StyledNavbarLeftLogo>
                        <img src={logoImage} alt="LogoImage" className="logoImage"/>
                        <StyledHeaderHeroHeading color={color.headingColorBlack} style={{textAlign:"center"}}>DTechno <br /><div style={{color : color.primary}}>Minds</div></StyledHeaderHeroHeading>
                    </StyledNavbarLeftLogo>
                    
                    </StyledNavbarWrapleft>
                    <StyledNavbarWrapRight>
                        
                        <StyledNavLinkContainer>
                            {headerNavigation.map((items)=>{
                                
                                return(
                                    <NavLink to={items.link} key={items.id}>{items.pageHeading}</NavLink>
                                )
                                
                            })}

                        </StyledNavLinkContainer>

                        <StyledNavBurgerButton onClick={handleOpenNav} 
                          className={btnColor ? "navHover" : ""}
                        >
                                <GiHamburgerMenu />
                        </StyledNavBurgerButton>
                    </StyledNavbarWrapRight>
                </StyledNavbarWrap>
                {!openNav && <StyledNavOpenPopup>
                    {headerNavigation.map((items)=>{
                                
                                return(
                                    <NavLink color={color.white} 
                                    padding="1rem 2rem"
                                    to={items.link} key={items.id}>{items.pageHeading}</NavLink>
                                )
                                
                            })}
                </StyledNavOpenPopup>}
            </StyledNavbar>
    </>
}


export default Header