import { StyledSectionContainer, StyledSectionwithoutbg, StyledLoadercard, ParagraphText } from "../style"

const Loader =()=>{
    return (
        <StyledSectionwithoutbg>
            <StyledSectionContainer>
                <StyledLoadercard>
                    <div className="loader"></div>
                    <div><ParagraphText>We prepare data for Please wait...</ParagraphText></div>
                </StyledLoadercard>
            </StyledSectionContainer>
        </StyledSectionwithoutbg>
    )
}




export default Loader