
import './App.css';
import Header from './components/header';
import {Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/footer';
import About from './pages/About';
import Team from './pages/Team';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Faq from './pages/Faq';
import Privacy from './pages/Privacy';
import DataSecurity from './pages/DataSecurity';
import Portfolio from './pages/Portfolio';
import History from './pages/History';
import { useEffect, useState } from 'react';
import Loader from './components/loader';
function App() {
  const [loading, setLoading]=useState(true)
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },2000)
    },[])

    if(loading){
        return <Loader />
    }

  return (
    <>
      
     
        <Header />
       { <Routes>
          <Route path='/' element={<Home />} />  
          <Route path='/about' element={<About />} /> 
          <Route path='/team' element={<Team />} />  
          <Route path='/contact' element={<Contact />} />
          <Route path='/services' element={<Services />} /> 
          <Route path='/faq' element={<Faq />} /> 
          <Route path='/privacy' element={<Privacy />} /> 
          <Route path='/datascurity' element={<DataSecurity />} /> 
          <Route path='/portfolio' element={<Portfolio />} />   
          <Route path='/history' element={<History />} /> 
          
        </Routes>
        }
        <Footer />
      
    </>
   
  );
}

export default App;
