export const sliderData = [
    {
        id: 1,
        heading1 : "Beautiful food & takeaway",
        heading2 : "delivered",
        paratext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
        btntext : "Place an Order",
        image :  "https://raw.githubusercontent.com/dinternationalit/dtm/main/src/images/slider/banner1.jpg?token=GHSAT0AAAAAACVO5V7EXPNWELSLQUF5XHLGZVYU6YA",
    },
    {
        id: 2,
        heading1 : "Beautiful food & takeaway",
        heading2 : "delivered",
        paratext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
        btntext : "Place an Order",
        image :  "https://raw.githubusercontent.com/dinternationalit/dtm/main/src/images/slider/banner2.jpg?token=GHSAT0AAAAAACVO5V7EEHNGIY2WUDUHD7AUZVR7BXQ",
    },
    {
        id: 3,
        heading1 : "Beautiful food & takeaway",
        heading2 : "delivered",
        paratext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
        btntext : "Place an Order",
        image :  "https://raw.githubusercontent.com/dinternationalit/dtm/main/src/images/slider/banner3.jpg?token=GHSAT0AAAAAACVO5V7E3NJPOMU5ZNQY3ESKZVRF4PA",
    },
    {
        id: 4,
        heading1 : "Beautiful food & takeaway",
        heading2 : "delivered",
        paratext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
        btntext : "Place an Order",
        image :  "https://raw.githubusercontent.com/dinternationalit/dtm/main/src/images/slider/banner4.jpg?token=GHSAT0AAAAAACVO5V7FA34RQXUVKGLOB3DUZVRFX5Q",
    },
]




export const mainCardData = [
    {
        id: 1,
        heading: "Improved Consistency",
        description :"Standardized processes ensure consistent project delivery",
        iconbg : "https://raw.githubusercontent.com/anujkit/dtmimage/main/box-icon-bg1.webp",
        footerIcon : "https://raw.githubusercontent.com/anujkit/dtmimage/main/icon-1.webp",
        iscardbottom : true
    },
    {
        id: 2,
        heading: "Enhanced Quality",
        description :"Integrated quality control ensures error-free solutions.",
        iconbg : "https://raw.githubusercontent.com/anujkit/dtmimage/main/box-icon-bg2.webp",
        footerIcon : "https://raw.githubusercontent.com/anujkit/dtmimage/main/icon-2.webp",
        iscardbottom : true
    },
    
    {
        id: 3,
        heading: "Increased Efficiency",
        description :"Streamlined workflows and optimized resource allocation lead to efficient project execution and timely delivery",
        iconbg : "https://raw.githubusercontent.com/anujkit/dtmimage/main/box-icon-bg3.webp",
        footerIcon : "https://raw.githubusercontent.com/anujkit/dtmimage/main/icon-3.webp",
        iscardbottom : true
    },
    {
        id: 4,
        heading: "Increased Efficiency",
        description :"Streamlined workflows and optimized resource allocation lead to efficient project execution and timely delivery",
        iconbg : "https://raw.githubusercontent.com/anujkit/dtmimage/main/box-icon-bg3.webp",
        footerIcon : "https://raw.githubusercontent.com/anujkit/dtmimage/main/icon-3.webp",
        iscardbottom : true
    },

]