import{ FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { GrVirtualMachine } from "react-icons/gr";
import { Link } from "react-router-dom"
import { StyledServiceCard,StyleHeadingsmall } from "../style";
const Services =({id, name, icon, description})=>{

    return(
    <StyledServiceCard>
        dssdsd
    </StyledServiceCard>
           
       

         
    )
}


export default Services