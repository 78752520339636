export const portfoliodata = [
    {
        id: 1,
        name: "Mera Farm",
        image: "https://mera.farm/wp-content/themes/hello-elementor-child/assets/images/carousel/img-4.jpg",
       description: "Elevate Your Business with Advanced ERP Software Development and Online Store Solutions. Welcome to DTechMinds, where innovation meets efficiency. We specialize in developing state-of-the-art ERP (Enterprise Resource Planning) software and creating dynamic online stores that empower businesses to reach new heights. Our solutions are designed to streamline operations, enhance productivity, and boost your online presence.",
        "category": "ERP"
    },
    {
        id: 2,
        name: "Dehradun Smart City",
        image: "https://www.dtechminds.com//images/portfolio/noimage.png",
       description: "Dehradun Smart City Project represent the all the functionalities and features of available for Dehradun citizen like property Tax, Water Tax, Electricity Bill Payment",
        "category": "ERP"
    },
    {
        id: 3,
        name: "MSewa Upyog Punjab",
        image: "https://www.dtechminds.com//images/portfolio/mSewaupyog.png",
       description: "Use it to organize any information that people need to find and reference easily, like your organisation's travel policies, design guidelines, or marketing assets",
        "category": "Cloud Solution"
    },
    {
        id: 4,
        name: "Dehradun Smart City",
        image: "https://www.dtechminds.com//images/portfolio/noimage.png",
       description: "Dehradun Smart City Project represent the all the functionalities and features of available for Dehradun citizen like property Tax, Water Tax, Electricity Bill Payment",
        "category": "EGov"
    }
];

